/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MeshGit(props) {
    const { nodes, materials } = useGLTF('/git.glb')
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Curve068.geometry}
                material={materials['base_0.033']}
                position={[0.484, -1.558, 1.744]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={28.972}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Curve069.geometry}
                material={materials['base_0.034']}
                position={[0.484, -1.575, 1.764]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={29.309}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Curve070.geometry}
                material={materials['base_0.035']}
                position={[0.484, -1.575, 1.764]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={29.309}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Curve071.geometry}
                material={materials['base_0.034']}
                position={[0.484, -1.575, 1.764]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={29.309}
            />
        </group>
    )
}

useGLTF.preload('/git.glb')

