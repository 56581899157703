/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import {useFrame} from "@react-three/fiber";

export function MeshPHP(props) {
    const group = useRef();
    const { nodes, materials } = useGLTF('/php.glb')
    useFrame(() => {
        // Rotate the mesh on each frame
        group.current.rotation.z -= 0;
    });
    return (
        <group {...props} dispose={null}>
            <group rotation={[Math.PI / 2, 0, 0]} scale={0.15}>
                <mesh ref={group}
                    castShadow
                    receiveShadow
                    geometry={nodes.LOGO.geometry}
                    material={materials.logo}
                    position={[-0.006, 0.384, 0.009]}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/php.glb')

