/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MeshTypeScript(props) {
    const { nodes, materials } = useGLTF('/typescript.clb')
    return (
        <group {...props} dispose={null}>
            <group scale={0.616}>
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube033.geometry}
                    material={materials.Material}
                    rotation={[Math.PI, 0, Math.PI]}
                />
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Curve.geometry}
                    material={materials['Material.034']}
                    position={[0.272, -0.275, 0.121]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={6.014}
                />
            </group>
        </group>
    )
}

useGLTF.preload('/typescript.clb')

