/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function MeshPython(props) {
    const { nodes, materials } = useGLTF('/python.glb')
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.D.geometry}
                material={materials.orange}
                position={[-0.03, 0.286, 0]}
                rotation={[-Math.PI / 2, 0, Math.PI]}
                scale={1.5}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.Top_snake.geometry}
                material={materials.blue}
                position={[0.008, -0.079, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={1.5}
            />
        </group>
    )
}

useGLTF.preload('/python.glb')

